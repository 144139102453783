import {
  Box,
  Container,
  Flex,
  Heading,
  Paragraph,
  Text,
} from '@theme-ui/components'
import React, { FC } from 'react'

export type HighlightModel = {
  digit: number | string
  title: string
}

export interface Highlights {
  title: string
  subtitle: string
  highlights: HighlightModel[]
}

export const Highlights: FC<Highlights> = ({ title, subtitle, highlights }) => {
  return (
    <Box
      sx={{
        textAlign: `center`,
        bg: `neutral.200`,
        py: 5,
        px: 2,
      }}
    >
      <Container>
        <Heading variant="centreHeading">{title}</Heading>
        {subtitle && <Paragraph>{subtitle}</Paragraph>}
        <Box>
          {highlights.map((highlight) => (
            <Paragraph sx={{ fontWeight: 600 }}>
              {highlight.title}:{' '}
              <Text
                sx={{
                  color: `primary`,
                  fontWeight: 600,
                }}
              >
                {highlight.digit}
              </Text>
            </Paragraph>
          ))}
        </Box>
      </Container>
    </Box>
  )
}
