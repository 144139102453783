import getVideoId from 'get-video-id'

export const getVideoUrl = (url: string): string => {
  const videoMeta = getVideoId(url)

  const videoServicePaths = {
    youtube: `https://www.youtube.com/embed/${videoMeta.id}`,
    vimeo: `https://player.vimeo.com/video/${videoMeta.id}&playsinline=0&responsive=1`,
    dailymotion: ``,
    vine: '',
    videopress: '',
    microsoftstream: '',
    tiktok: '',
  }
  if (videoMeta.service) {
    return videoServicePaths[videoMeta.service]
  }
  return ''
}
