import React, { FC } from 'react'
import { navigate } from 'gatsby'
import { RouteComponentProps } from '@reach/router'

import { isLoggedIn } from '../../utils/auth'

type PrivateRouteProps = RouteComponentProps & {
  component: React.FC<RouteComponentProps>
}

export const PrivateRoute: FC<PrivateRouteProps> = ({
  component: Component,
  location,
  ...rest
}) => {
  if (!isLoggedIn() && location?.pathname !== `/locker/login`) {
    navigate('/locker/login')
    return null
  }
  return <Component {...rest} />
}
