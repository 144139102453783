import React from 'react'
import { FC, ReactNode } from 'react'
import { Box } from 'theme-ui'

type ProfileStatProps = {
  title: string
  children: ReactNode
}

export const ProfileStat: FC<ProfileStatProps> = ({ title, children }) => {
  return (
    <Box>
      <strong>{title}:</strong> {children}
    </Box>
  )
}
