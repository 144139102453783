import React, { FC, ReactNode } from 'react'
import { Grid, Box, Heading, Paragraph, Container } from '@theme-ui/components'
import { Layout } from '../components/layout/layout'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { RichTextContent } from '../components/rich-text/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import { SEO } from '../components/seo/seo'
import { ITeam } from '../types/teams'
import { clamp } from '../utils/clamp'

type TeamPageProps = {
  contentfulTeam: ITeam
}

const TeamPage: FC<PageProps<TeamPageProps>> = ({ data }) => {
  const { contentfulTeam } = data

  const dates =
    contentfulTeam.startDate === contentfulTeam.endDate
      ? contentfulTeam.startDate
      : `${contentfulTeam.startDate} - ${contentfulTeam.endDate}`

  return (
    <Layout>
      <SEO title={contentfulTeam.teamName} />
      <Box bg="neutral.300" py={5} sx={{ textAlign: 'center' }}>
        <Container>
          <Heading
            sx={{
              fontSize: 5,
              fontWeight: 500,
              textTransform: 'uppercase',
            }}
          >
            {contentfulTeam.teamName}
          </Heading>
          <Paragraph
            sx={{
              my: 0,
              fontSize: 3,
            }}
            color="neutral.500"
          >
            {dates}
          </Paragraph>
        </Container>
      </Box>
      <Box
        sx={{
          // bg: `neutral.200`,
          py: [4, 5],
        }}
      >
        <Container>
          <Grid columns={[1, 1, '2fr 1fr']} gap={[2, 2, 4]}>
            <Box>
              {contentfulTeam.teamHistory && (
                <Box>
                  <Heading
                    sx={{
                      fontWeight: 600,
                      textTransform: 'uppercase',
                    }}
                  >
                    Team History
                  </Heading>
                  <Box sx={{ fontSize: 3 }}>
                    <RichTextContent
                      renderNodeOverrides={{
                        [BLOCKS.LIST_ITEM]: (_, children): ReactNode => {
                          return (
                            <Paragraph m={0} p={0}>
                              {children}
                            </Paragraph>
                          )
                        },
                        [BLOCKS.UL_LIST]: (_, children): ReactNode => {
                          return <>{children}</>
                        },
                      }}
                    >
                      {contentfulTeam.teamHistory}
                    </RichTextContent>
                  </Box>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                justifySelf: 'center',
              }}
            >
              <GatsbyImage
                image={contentfulTeam.teamLogo.gatsbyImageData}
                alt={''}
              />
            </Box>
          </Grid>
        </Container>
      </Box>
      <Container>
        {contentfulTeam.gallery && (
          <Box mt={5} sx={{ textAlign: 'center' }}>
            <Heading
              sx={{
                fontWeight: 600,
                textTransform: 'uppercase',
              }}
            >
              Gallery
            </Heading>
            <Grid
              columns={[1, 1, clamp(contentfulTeam.gallery.length, 3, 1)]}
              mt={4}
            >
              {contentfulTeam.gallery?.map((image) => (
                <GatsbyImage image={image.gatsbyImageData} alt="" />
              ))}
            </Grid>
          </Box>
        )}
      </Container>

      <Container mt={5}>
        <Box sx={{ textAlign: 'center' }}>
          {contentfulTeam.teamResults && (
            <Box>
              <Heading
                sx={{
                  fontWeight: 600,
                  textTransform: 'uppercase',
                }}
              >
                Team Results
              </Heading>
              <Box>
                <RichTextContent
                  renderNodeOverrides={{
                    [BLOCKS.PARAGRAPH]: (_, children): ReactNode => {
                      return (
                        <Box m={0} p={0}>
                          {children}
                        </Box>
                      )
                    },
                    [BLOCKS.HEADING_3]: (_, children): ReactNode => {
                      return (
                        <Heading
                          as="h3"
                          mt={4}
                          sx={{
                            fontSize: [4, 5],
                            fontWeight: 500,
                            color: 'primary',
                          }}
                        >
                          {children}
                        </Heading>
                      )
                    },
                    [BLOCKS.HEADING_4]: (_, children): ReactNode => {
                      return (
                        <Heading as="h4" mt={4}>
                          {children}
                        </Heading>
                      )
                    },
                  }}
                >
                  {contentfulTeam.teamResults}
                </RichTextContent>
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query TeamPage($slug: String!) {
    contentfulTeam(slug: { eq: $slug }) {
      teamName
      teamHistory {
        raw
      }
      teamResults {
        raw
      }
      gallery {
        gatsbyImageData(
          width: 720
          aspectRatio: 1.5
          placeholder: BLURRED
          layout: FULL_WIDTH
        )
      }
      riders {
        name
        results {
          raw
        }
      }
      startDate(formatString: "YYYY")
      endDate(formatString: "YYYY")
      teamLogo {
        gatsbyImageData(width: 420, aspectRatio: 1, placeholder: BLURRED)
      }
    }
  }
`

export default TeamPage
