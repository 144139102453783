import React, { FC } from 'react'
import { Box, Heading, Container, Paragraph } from '@theme-ui/components'
import { Layout } from '../components/layout/layout'

import { SEO } from '../components/seo/seo'
import { InternalLink } from '../components/internal-link/internal-link'

const PageNotFound: FC = () => {
  return (
    <Layout>
      <SEO title="Page not found" />
      <Box mt={5} sx={{ textAlign: 'center' }}>
        <Container>
          <Heading
            sx={{
              fontSize: 5,
              fontWeight: 500,
              textTransform: 'uppercase',
            }}
          >
            Page not found
          </Heading>
          <Paragraph>
            Sorry, but the page you are looking for could not be found.
          </Paragraph>
          <Paragraph>
            <InternalLink url="/">Back to Homepage</InternalLink>
          </Paragraph>
        </Container>
      </Box>
    </Layout>
  )
}

export default PageNotFound
