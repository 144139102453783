exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-athletes-tsx": () => import("./../../../src/pages/athletes.tsx" /* webpackChunkName: "component---src-pages-athletes-tsx" */),
  "component---src-pages-clients-tsx": () => import("./../../../src/pages/clients.tsx" /* webpackChunkName: "component---src-pages-clients-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-locker-tsx": () => import("./../../../src/pages/locker/[...].tsx" /* webpackChunkName: "component---src-pages-locker-tsx" */),
  "component---src-pages-logos-tsx": () => import("./../../../src/pages/logos.tsx" /* webpackChunkName: "component---src-pages-logos-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-teams-tsx": () => import("./../../../src/pages/teams.tsx" /* webpackChunkName: "component---src-pages-teams-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-athlete-tsx": () => import("./../../../src/templates/athlete.tsx" /* webpackChunkName: "component---src-templates-athlete-tsx" */),
  "component---src-templates-team-tsx": () => import("./../../../src/templates/team.tsx" /* webpackChunkName: "component---src-templates-team-tsx" */)
}

