import React from 'react'
import { FC } from 'react'
import { Box } from 'theme-ui'

export const ProfileSpacer: FC = () => {
  return (
    <Box
      sx={{
        borderBottom: '2px solid',
        borderColor: 'primary',
        width: 64,
        my: 3,
      }}
    />
  )
}
