import React, { FC } from 'react'
import { Box, Heading, Container, Link, Paragraph } from '@theme-ui/components'
import { Layout } from '../components/layout/layout'
import { graphql, PageProps } from 'gatsby'
import { SEO } from '../components/seo/seo'
import { ICompanyLogos } from 'src/types/logos'

const HumanMimes: Record<string, string> = {
  'application/pdf': 'PDF',
  'image/jpeg': 'JPG',
  'image/png': 'PNG',
  'image/x-eps': 'EPS',
  'application/postscript': 'AI',
  'image/svg+xml': 'SVG',
  'image/tiff': 'TIFF',
}

type LogosPageProps = {
  allContentfulCompanyLogoFiles: {
    nodes: ICompanyLogos[]
  }
}

const LogosPage: FC<PageProps<LogosPageProps>> = ({ data }) => {
  const { allContentfulCompanyLogoFiles } = data

  return (
    <Layout>
      <SEO title="Logos" />
      <Box mt={5} sx={{ textAlign: 'center' }}>
        <Container>
          <Heading
            sx={{
              fontSize: 5,
              fontWeight: 500,
              textTransform: 'uppercase',
            }}
          >
            Logos
          </Heading>
          <Paragraph>
            To download a logo, choose the logo and format you need from the
            list below.
          </Paragraph>
        </Container>
      </Box>
      <Box my={[4, 5]}>
        <Container>
          {allContentfulCompanyLogoFiles.nodes.map((company) => (
            <Box key={company.name} mb={4} sx={{ textAlign: 'center' }}>
              <Heading>{company.name}</Heading>
              {company.files &&
                company.files.map((file) => (
                  <Box key={file.filename}>
                    <Link
                      href={file.url}
                      sx={{
                        textDecoration: 'none',
                      }}
                      download
                    >
                      {file.title} ({HumanMimes[file.mimeType] || file.mimeType}
                      )
                    </Link>
                  </Box>
                ))}
            </Box>
          ))}
        </Container>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query LogosPageQuery {
    allContentfulCompanyLogoFiles(sort: { fields: companyName, order: ASC }) {
      nodes {
        name: companyName
        files {
          url
          title
          mimeType
        }
      }
    }
  }
`

export default LogosPage
