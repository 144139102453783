import React, { FC } from 'react'

import { Router, Redirect } from '@reach/router'
import { PrivateRoute } from '../../components/private-route/private-route'
import { Layout } from '../../components/layout/layout'
import { LoginPage } from '../../components/locker/login'
import { LockerPage } from '../../components/locker/locker'

const Locker: FC = () => (
  <Layout>
    <Router>
      <LoginPage path="/locker/login" />
      <PrivateRoute path="/locker" component={LockerPage} />
      <Redirect from="/" to="/locker" default noThrow />
    </Router>
  </Layout>
)

export default Locker
