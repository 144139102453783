import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import { RichText } from '../types/richtext'
import { AccordionItem } from '../types/accordion'

export const transformAccordion = (
  content: RenderRichTextData<ContentfulRichTextGatsbyReference>
): AccordionItem[] | null => {
  if (!content?.raw) return null
  const parsedRichText: RichText = JSON.parse(content.raw)

  const parsedHistoryHeaders = parsedRichText.content.filter(
    (item) => item.nodeType === BLOCKS.HEADING_3
  )

  const parsedHistoryList = parsedRichText.content.filter(
    (item) => item.nodeType === BLOCKS.UL_LIST
  )

  const accordionRichText: AccordionItem[] = parsedHistoryHeaders.map(
    (header, item) => ({
      title: header.content[0].value,
      content: {
        references: [],
        raw: JSON.stringify({
          nodeType: 'document',
          content: [parsedHistoryList[item]],
        }),
      },
    })
  )

  return accordionRichText
}
