import { GatsbyFunctionRequest } from 'gatsby'

const isBrowser = typeof window !== `undefined`

const getToken = (): string | null => window.localStorage.getItem('userToken')

export const setToken = (token: string) => {
  if (!isBrowser) return
  window.localStorage.setItem('userToken', token)
}

export const isLoggedIn = () => {
  if (!isBrowser) return false

  const token = getToken()

  return !!token
}

export const getCurrentToken = () => isBrowser && getToken()

export const logout = (callback: () => void) => {
  if (!isBrowser) return

  window.localStorage.removeItem('userToken')
  callback()
}

export const getAuthorizationToken = (req: GatsbyFunctionRequest) => {
  const token = req.headers.authorization
  if (token) {
    const match = token.match(/^Bearer (.+)$/)
    if (match) {
      return match[1]
    }
  }
  return null
}
