import React, { FC } from 'react'
import { Grid, Box, Heading, Paragraph, Container } from '@theme-ui/components'
import { Layout } from '../components/layout/layout'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { InternalLink } from '../components/internal-link/internal-link'
import { ITeam } from '../types/teams'
import { SEO } from '../components/seo/seo'

type TeamsPageProps = {
  allContentfulTeam: {
    nodes: ITeam[]
  }
}

const TeamsPage: FC<PageProps<TeamsPageProps>> = ({ data }) => {
  const { allContentfulTeam } = data

  return (
    <Layout>
      <SEO title="Teams" />
      <Box mt={5} sx={{ textAlign: 'center' }}>
        <Container>
          <Heading
            sx={{
              fontSize: 5,
              fontWeight: 500,
              textTransform: 'uppercase',
            }}
          >
            Teams
          </Heading>
        </Container>
      </Box>
      <Box my={[4, 5]}>
        <Container>
          <Grid columns={[1, 2, 4]} gap={[4, 4, 4]} mt={4}>
            {allContentfulTeam.nodes.map((team) => (
              <Box key={team.slug} sx={{ textAlign: 'center' }}>
                <InternalLink url={`/teams/${team.slug}/`}>
                  <GatsbyImage
                    image={team.teamLogo?.gatsbyImageData}
                    alt={team.teamName}
                  />
                </InternalLink>
                <InternalLink
                  url={`/teams/${team.slug}/`}
                  sx={{
                    textDecoration: 'none',
                  }}
                >
                  <Heading
                    sx={{
                      fontWeight: 600,
                      fontSize: 2,
                      textTransform: 'uppercase',
                      color: 'primary',
                    }}
                  >
                    {team.teamName}
                  </Heading>
                </InternalLink>
                <Paragraph
                  sx={{
                    my: 0,
                  }}
                >
                  ({team.startDate} - {team.endDate})
                </Paragraph>
              </Box>
            ))}
          </Grid>
        </Container>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query TeamsPageQuery {
    allContentfulTeam(sort: { order: ASC, fields: [startDate] }) {
      nodes {
        teamName
        slug
        startDate(formatString: "YYYY")
        endDate(formatString: "YYYY")
        teamLogo {
          title
          gatsbyImageData(width: 240, aspectRatio: 1, placeholder: BLURRED)
        }
      }
    }
  }
`

export default TeamsPage
