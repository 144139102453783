import { Box, Grid, Heading, Paragraph } from '@theme-ui/components'
import React, { FC } from 'react'
import { InternalLink } from '../internal-link/internal-link'

export interface LogoCardProps {
  name: string
  team?: string
  url: string
  renderImage: React.ReactNode
  contractStart?: string
  contractEnd?: string
}

export const ProfileCard: FC<LogoCardProps> = ({
  renderImage,
  name,
  url,
  team,
  contractStart,
  contractEnd,
}) => {
  const contractDates =
    contractStart === contractEnd
      ? `(${contractStart} - ${contractEnd})`
      : `(${contractEnd})`
  return (
    <Grid
      sx={{
        gridTemplateRows: `auto 1fr`,
        justifyItems: `center`,
        textAlign: 'center',
      }}
    >
      <InternalLink
        url={url}
        sx={{
          textDecoration: `none`,
        }}
      >
        {renderImage}
      </InternalLink>
      <Box>
        <Heading
          as="p"
          sx={{
            color: `primary`,
            textTransform: `uppercase`,
            fontSize: [1, 2, 2],
          }}
        >
          <InternalLink
            url={url}
            sx={{
              textDecoration: `none`,
            }}
          >
            {name}
          </InternalLink>
        </Heading>
        {team && (
          <Paragraph
            m={0}
            sx={{ fontSize: [1, 1, 2], textTransform: 'uppercase' }}
          >
            {team}
          </Paragraph>
        )}
        {contractEnd && <Paragraph>{contractDates}</Paragraph>}
      </Box>
    </Grid>
  )
}
