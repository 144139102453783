import React, { FC, useEffect } from 'react'
import { Box, Heading, Paragraph, Container } from '@theme-ui/components'
import { SEO } from '../seo/seo'
import { getCurrentToken, logout } from '../../utils/auth'
import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { LockerResponse } from '../../api/locker'
import { Button, Link } from 'theme-ui'
import { useQuery } from 'react-query'
import axios from 'axios'

export const LockerPage: FC = () => {
  const {
    data: response,
    error,
    isError,
    isLoading,
  } = useQuery(
    'locker',
    async () => {
      const token = getCurrentToken()
      const { data } = await axios.get<LockerResponse>('/api/locker', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return data
    },
    {
      refetchInterval: 120000,
      retry: false,
    }
  )

  useEffect(() => {
    if (!isError) return
    logout(() => navigate('/locker/login'))
  }, [error])

  if (isLoading) {
    return (
      <Container my={7} sx={{ textAlign: 'center' }}>
        <FontAwesomeIcon icon={faSpinner} pulse size="5x" />
      </Container>
    )
  }

  if (!response) {
    return null
  }

  const handleLogout = () => {
    logout(() => navigate('/locker/login'))
  }

  return (
    <>
      <SEO title="Welcome to the Locker Room" />

      <Container mt={4} sx={{ textAlign: 'center', maxWidth: 720 }}>
        <Box my={4}>
          <img src="https://d2cge4k61lammf.cloudfront.net/ims/site/black-betty-large.jpg" />
          <Heading variant="centreHeading" color="primary">
            Hey {response.nickname || response.name}!
          </Heading>
          <Paragraph>
            As your personal assistant, it's my job to make sure you have all
            the important documents and files in your locker which you need or I
            think you'll find useful.
          </Paragraph>
          <Paragraph>
            Your locker should have everything you need for now. You'll always
            find the latest files are on top. Just how I like it ;)
          </Paragraph>

          <Paragraph>With love, Betty x</Paragraph>
          <Box>
            <Button onClick={handleLogout}>Logout</Button>
          </Box>
          <Heading variant="centreHeading" mt={5}>
            Your Documents
          </Heading>
          {response.files?.map((document) => (
            <Box my={3}>
              <Paragraph mb={0}>
                <Link
                  href={document.url}
                  target="_blank"
                  key={document.fileName}
                >
                  {document.fileName}
                </Link>
              </Paragraph>{' '}
              <Paragraph sx={{ my: 0, fontSize: 0 }}>
                ({(document.details.size / 100000).toPrecision(2)}mb)
              </Paragraph>
            </Box>
          ))}
        </Box>
      </Container>
    </>
  )
}
