import React, { FC, useRef } from 'react'
import {
  Grid,
  Box,
  Heading,
  Paragraph,
  Container,
  IconButton,
} from '@theme-ui/components'
import { Layout } from '../components/layout/layout'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image'
import { HighlightModel, Highlights } from '../components/highlights/highlights'
import { ProfileCard } from '../components/profile-card/profile-card'
import { IAthlete } from '../types/athletes'
import { IArticle } from '../types/articles'
import { InternalLink } from '../components/internal-link/internal-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons'

type HomepageProps = {
  allContentfulAthlete: {
    nodes: IAthlete[]
  }
  allContentfulArticle: {
    nodes: IArticle[]
  }
  mainImage: {
    title: string
    gatsbyImageData: IGatsbyImageData
  }
}

const highlights: HighlightModel[] = [
  {
    digit: 9,
    title: `World Cup Overall Titles`,
  },
  {
    digit: 47,
    title: `World Cup Race Wins`,
  },
  {
    digit: 141,
    title: `World Cup Podiums`,
  },
  {
    digit: 4,
    title: `World Cup Overall Team Championship`,
  },
  {
    digit: '6 Gold, 7 Silver & 4 Bronze',
    title: `World Championship Medals`,
  },
]

const App: FC<PageProps<HomepageProps>> = ({ data }) => {
  const { allContentfulAthlete, allContentfulArticle, mainImage } = data

  const scrollRef = useRef<null | HTMLDivElement>(null)
  const executeScroll = () =>
    scrollRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  return (
    <Layout>
      <Box bg="neutral.700">
        <Container>
          <Grid
            gap={4}
            columns={[1, 1, '1fr 1fr']}
            sx={{
              minHeight: '75vh',
              alignItems: `center`,
              justifyItems: 'center',
              color: 'white',
            }}
          >
            <Box>
              <Heading variant="introHeading">
                Welcome to 23 Degrees Sports
              </Heading>
              <Paragraph sx={{ fontSize: 2, mt: 0 }}>
                Since its establishment in 2000, 23 Degrees Sports has served as
                a leading provider of management and support services to top
                mountain bike athletes, organizations, and events.
              </Paragraph>
              <Paragraph>
                Under the leadership of founder Martin Whiteley, we have
                overseen the careers of numerous world-class athletes, designed
                the 2004 Olympic cross-country course, and managed four highly
                successful teams on the World Cup circuit: Global Racing Team,
                Team G Cross Honda, Trek World Racing, and YT Mob.
              </Paragraph>
              <Paragraph>
                Through its dedication to excellence and innovation, 23 Degrees
                Sports has earned a reputation as a trusted partner in the
                sports industry.
              </Paragraph>
            </Box>
            <Box>
              <GatsbyImage image={mainImage.gatsbyImageData} alt="23 Degrees" />
            </Box>
          </Grid>
          <Box py={4} sx={{ textAlign: 'center' }}>
            <IconButton
              onClick={executeScroll}
              sx={{
                color: 'white',
                fontSize: 4,
                cursor: 'pointer',
                transition: 'all 200ms ease-in-out',
                '&:hover': {
                  color: 'neutral.500',
                },
              }}
            >
              <FontAwesomeIcon icon={faAngleDoubleDown} />
            </IconButton>
          </Box>
        </Container>
      </Box>
      <Box ref={scrollRef}></Box>
      <Highlights
        title="Track Record"
        highlights={highlights}
        subtitle={`By the end of the 2024 mountain bike race season, 23 Degrees had accumulated the following tallies across its first four teams`}
      />
      <Box my={[4, 5]}>
        <Container>
          <Heading variant="centreHeading">Our Athletes</Heading>
          <Grid columns={[2, 4]} gap={[4, 4, 4]} mt={4}>
            {allContentfulAthlete.nodes.map((athlete) => (
              <ProfileCard
                key={`athlete-` + athlete.slug}
                name={athlete.name}
                team={athlete.teamName}
                renderImage={
                  <Box sx={{ borderRadius: '50%', overflow: 'hidden' }}>
                    <GatsbyImage
                      image={athlete.profileImage?.gatsbyImageData}
                      alt={athlete.name || ``}
                      style={{ borderRadius: '50%' }}
                      imgStyle={{ borderRadius: '50%' }}
                    />
                  </Box>
                }
                url={`/athletes/${athlete.slug}/`}
              />
            ))}
          </Grid>
        </Container>
      </Box>
      <Box my={[4, 5]}>
        <Container>
          <Heading variant="centreHeading">Our Clients</Heading>
          <Paragraph sx={{ textAlign: 'center' }}>
            Over the years, 23 Degrees Sports has worked with numerous leading
            clients in the sports industry, building a reputation for excellence
            and reliability. Whether you are a professional sports team, a
            sports governing body, or a corporate sponsor, we can provide the
            expertise and support you need to achieve your goals.
          </Paragraph>
          <Grid
            columns={[3, 8]}
            gap={[4, 4, 4]}
            mt={4}
            sx={{ alignItems: 'center' }}
          >
            <Box
              sx={{
                justifySelf: 'center',
              }}
            >
              <StaticImage
                src="../images/logos/Polygon_Bikes_logo.jpg"
                alt={''}
              />
            </Box>
            <Box
              sx={{
                justifySelf: 'center',
              }}
            >
              <StaticImage src="../images/logos/image2.png" alt={''} />
            </Box>
            <Box
              sx={{
                justifySelf: 'center',
              }}
            >
              <StaticImage src="../images/logos/image4.jpg" alt={''} />
            </Box>
            <Box
              sx={{
                justifySelf: 'center',
              }}
            >
              <StaticImage src="../images/logos/monster-logo.png" alt={''} />
            </Box>
            <Box
              sx={{
                justifySelf: 'center',
              }}
            >
              <StaticImage src="../images/logos/image3.jpg" alt={''} />
            </Box>
            <Box
              sx={{
                justifySelf: 'center',
              }}
            >
              <StaticImage
                src="../images/logos/image6.png"
                alt={''}
                width={80}
              />
            </Box>
            <Box
              sx={{
                justifySelf: 'center',
              }}
            >
              <StaticImage
                src="../images/logos/image5.png"
                alt={''}
                width={160}
                aspectRatio={16 / 9}
              />
            </Box>
            <Box
              sx={{
                justifySelf: 'center',
              }}
            >
              <StaticImage
                src="../images/logos/image1.jpg"
                alt={''}
                width={160}
              />
            </Box>
          </Grid>
        </Container>
      </Box>
      <Box my={[4, 5]}>
        <Container>
          <Heading variant="centreHeading">Latest News</Heading>
          <Grid columns={[1, 2, 3]} gap={[4, 4, 3]} mt={4}>
            {allContentfulArticle.nodes.map((article) => (
              <Box key={article.slug}>
                <InternalLink url={`/news/${article.slug}/`}>
                  <GatsbyImage
                    image={article.featuredImage?.gatsbyImageData}
                    alt={article.featuredImage?.title}
                  />
                </InternalLink>
                <Paragraph
                  sx={{
                    my: 2,
                    fontWeight: 600,
                    color: 'neutral.500',
                  }}
                >
                  {article.originallyPublished || article.createdAt}
                </Paragraph>
                <InternalLink
                  url={`/news/${article.slug}/`}
                  sx={{
                    textDecoration: 'none',
                  }}
                >
                  <Heading
                    sx={{
                      fontWeight: 500,
                      fontSize: 3,
                      textTransform: 'uppercase',
                      color: 'primary',
                    }}
                  >
                    {article.title}
                  </Heading>
                </InternalLink>
              </Box>
            ))}
          </Grid>
        </Container>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query HomepageQuery {
    mainImage: contentfulAsset(
      contentful_id: { eq: "3zQPwOSOZIDpJGzLt2HjX9" }
    ) {
      gatsbyImageData(width: 1280, placeholder: BLURRED)
    }

    allContentfulAthlete(
      filter: { managed: { eq: true } }
      sort: { order: ASC, fields: [contractStart] }
    ) {
      nodes {
        name
        slug
        teamName
        profileImage {
          title
          gatsbyImageData(width: 420, aspectRatio: 1, placeholder: BLURRED)
        }
      }
    }
    allContentfulArticle(
      limit: 3
      sort: { order: DESC, fields: [originallyPublished, createdAt] }
    ) {
      nodes {
        title
        slug
        createdAt(formatString: "DD MMM YYYY")
        originallyPublished(formatString: "DD MMM YYYY")
        featuredImage {
          title
          gatsbyImageData(
            width: 420
            aspectRatio: 1.75
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
`

export default App
