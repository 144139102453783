import React, { FC, useEffect } from 'react'
import {
  Box,
  Heading,
  Container,
  Grid,
  Paragraph,
  Link,
  Input,
  Label,
  Textarea,
  Button,
} from '@theme-ui/components'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useAxios, useAxiosError } from '../../hooks/useAxios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3'
import { RouteComponentProps } from '@reach/router'

import { SEO } from '../../components/seo/seo'
import { LoginData } from '../../types/login'
import { StaticImage } from 'gatsby-plugin-image'
import { isLoggedIn, setToken } from '../../utils/auth'
import { navigate } from 'gatsby'

const schema = yup
  .object({
    password: yup.string().required(),
    token: yup.string(),
  })
  .required()

type Error = {
  error?: useAxiosError
  message?: string
}

const ErrorMessage: FC<Error> = ({ error, message }) => {
  if (!error && !message) return null

  return (
    <Paragraph my={0} sx={{ fontSize: 0 }} color="primary">
      {message || error?.message}
    </Paragraph>
  )
}

const LoginForm: FC = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const {
    getValues,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<LoginData>({
    resolver: yupResolver(schema),
  })

  const { request, response, loading, error } = useAxios<{ token: string }>()

  useEffect(() => {
    if (!response?.token) return
    setToken(response.token)
    navigate('/locker')
  }, [response])

  const onSubmit = handleSubmit(async () => {
    if (!executeRecaptcha) {
      console.log('Recaptcha missing')
      return
    }
    const result = await executeRecaptcha('login')
    setValue('token', result)
    await request.post('/api/auth/login', getValues())
    reset()
  })

  if (isLoggedIn()) {
    navigate('/locker/')
  }

  return (
    <>
      <SEO title="Locker Room Login" />
      <Container sx={{ maxWidth: 420, my: [4, 5], textAlign: 'center' }}>
        <StaticImage src="../../images/black-betty-large.jpg" alt={''} />
        <Box mt={3}>
          <Box as="form" onSubmit={onSubmit}>
            <Box>
              <Input
                {...register('password', { required: true })}
                sx={{
                  borderColor: errors.password ? 'primary' : 'initial',
                }}
                type="password"
                placeholder="Enter your password"
              />
              <ErrorMessage message={errors.password?.message} />
            </Box>

            <Button mt={4} type="submit" disabled={loading}>
              {loading ? (
                <>
                  <FontAwesomeIcon icon={faSpinner} pulse /> Submitting...
                </>
              ) : (
                'Login'
              )}
            </Button>
          </Box>
          <Box mt={3}>
            <ErrorMessage error={error} />
          </Box>
        </Box>
      </Container>
    </>
  )
}

export const LoginPage: FC<RouteComponentProps> = () => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}>
    <LoginForm />
  </GoogleReCaptchaProvider>
)
