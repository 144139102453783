import React, { FC } from 'react'
import { Box, Heading, Paragraph, Container, Grid } from '@theme-ui/components'
import { Layout } from '../components/layout/layout'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { RichTextContent } from '../components/rich-text/rich-text'
import { SEO } from '../components/seo/seo'
import { IArticle } from '../types/articles'
import { ProfileCard } from '../components/profile-card/profile-card'

type ArticlePageProps = {
  contentfulArticle: IArticle
}

const App: FC<PageProps<ArticlePageProps>> = ({ data }) => {
  const { contentfulArticle } = data

  return (
    <Layout>
      <SEO title={contentfulArticle.title} />
      <Box mt={5} sx={{ textAlign: 'center' }}>
        <Container sx={{ maxWidth: 720 }}>
          <Box>
            <Heading
              sx={{
                fontSize: [4, 5],
                fontWeight: 600,
                textTransform: 'uppercase',
              }}
            >
              {contentfulArticle.title}
            </Heading>
            <Paragraph
              sx={{ mt: 2, mb: 4, color: 'neutral.500', fontWeight: 600 }}
            >
              Published{' '}
              {contentfulArticle.originallyPublished ||
                contentfulArticle.createdAt}
            </Paragraph>
          </Box>
        </Container>
        {contentfulArticle.featuredImage.width >= 120 && (
          <Container>
            <GatsbyImage
              image={contentfulArticle.featuredImage.gatsbyImageData}
              alt={contentfulArticle.featuredImage.title}
            />
          </Container>
        )}
      </Box>
      <Container sx={{ lineHeight: 2, mt: 4, maxWidth: 960 }}>
        <Heading
          sx={{
            color: 'primary',
            fontSize: [3, 4],
            fontWeight: 600,
            lineHeight: 1.5,
          }}
        >
          {contentfulArticle.subtitle}
        </Heading>
        <Box
          sx={{
            fontSize: [2, 2, 3],
            color: 'neutral.600',
          }}
        >
          <RichTextContent>{contentfulArticle.content}</RichTextContent>
        </Box>

        {contentfulArticle.relatedAthletes && (
          <>
            <Heading
              as="h4"
              mt={4}
              sx={{
                fontWeight: 600,
                fontSize: [2, 2, 3],
                textTransform: 'uppercase',
              }}
            >
              In this article
            </Heading>
            <Grid
              columns={[2, 4]}
              gap={[4, 4, 5]}
              mt={4}
              sx={{ lineHeight: 'initial' }}
            >
              {contentfulArticle.relatedAthletes?.map((athlete) => (
                <ProfileCard
                  key={`athlete-` + athlete.slug}
                  name={athlete.name}
                  team={athlete.teamName}
                  renderImage={
                    <Box sx={{ borderRadius: '50%', overflow: 'hidden' }}>
                      <GatsbyImage
                        image={athlete.profileImage?.gatsbyImageData}
                        alt={athlete.name || ``}
                        style={{ borderRadius: '50%' }}
                        imgStyle={{ borderRadius: '50%' }}
                      />
                    </Box>
                  }
                  url={`/athletes/${athlete.slug}/`}
                />
              ))}
            </Grid>
          </>
        )}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ArticlePage($slug: String!) {
    contentfulArticle(slug: { eq: $slug }) {
      title
      subtitle
      slug
      createdAt(formatString: "DD MMM YYYY")
      originallyPublished(formatString: "DD MMM YYYY")
      featuredImage {
        title
        gatsbyImageData(width: 1280, placeholder: BLURRED)
        width
      }
      content {
        raw
      }
      relatedAthletes {
        name
        teamName
        slug
        profileImage {
          title
          gatsbyImageData(width: 720, aspectRatio: 1, placeholder: BLURRED)
        }
      }
    }
  }
`

export default App
