import React, { FC, useState } from 'react'
import {
  Box,
  Heading,
  Container,
  Grid,
  Paragraph,
  Link,
  Input,
  Label,
  Textarea,
  Button,
} from '@theme-ui/components'
import { Layout } from '../components/layout/layout'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3'

import { ContactFormData } from '../types/contact'
import { SEO } from '../components/seo/seo'
import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'

const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    message: yup.string().required(),
    token: yup.string(),
  })
  .required()

type Error = {
  children?: string
}

const ErrorMessage: FC<Error> = ({ children }) => {
  if (!children) return null

  return (
    <Paragraph my={0} sx={{ fontSize: 0 }} color="primary">
      {children}
    </Paragraph>
  )
}

const ContactPage: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const {
    getValues,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ContactFormData>({
    resolver: yupResolver(schema),
  })

  const mutation = useMutation((contactQuery: ContactFormData) =>
    axios.post('/api/contact', contactQuery)
  )

  const onSubmit = handleSubmit(async () => {
    if (!executeRecaptcha) {
      console.log('No recaptcha')
      return
    }
    setIsLoading(true)
    const result = await executeRecaptcha('contact')
    setValue('token', result)
    mutation.mutate(getValues(), {
      onSuccess: () => {
        setIsLoading(false)
        reset()
      },
      onError: () => setIsLoading(false),
    })
  })

  return (
    <Layout>
      <SEO title="Contact Us" />
      <Box mt={5} sx={{ textAlign: 'center' }}>
        <Container>
          <Heading
            sx={{
              fontSize: 5,
              fontWeight: 500,
              textTransform: 'uppercase',
            }}
          >
            Contact Us
          </Heading>
        </Container>
      </Box>
      <Box my={[4, 5]}>
        <Container>
          <Grid columns={[1, 1, '2fr 1fr']} gap={6}>
            <Box>
              <>
                {mutation.isSuccess && (
                  <Box
                    bg="neutral.300"
                    p={4}
                    sx={{
                      textAlign: 'center',
                      p: 4,
                      bg: 'neutral.300',
                      mb: 4,
                    }}
                  >
                    <Heading
                      sx={{
                        fontWeight: 500,
                        color: 'primary',
                      }}
                    >
                      Thank you for your enquiry
                    </Heading>
                    <Paragraph>
                      Your message has been sent and will be reviewed shortly.
                    </Paragraph>
                  </Box>
                )}
              </>
              <Box as="form" onSubmit={onSubmit}>
                {mutation.isError && (
                  <ErrorMessage>
                    There was an error. Please try again later.
                  </ErrorMessage>
                )}
                <Box>
                  <Label htmlFor="name">Name</Label>
                  <Input
                    {...register('name', { required: true })}
                    sx={{
                      borderColor: errors.name ? 'primary' : 'initial',
                    }}
                  />
                  <ErrorMessage>{errors.name?.message}</ErrorMessage>
                </Box>
                <Box mt={2}>
                  <Label htmlFor="email">Email address</Label>
                  <Input
                    sx={{
                      borderColor: errors.email ? 'primary' : 'initial',
                    }}
                    {...register('email')}
                  />
                  <ErrorMessage>{errors.email?.message}</ErrorMessage>
                </Box>
                <Box mt={2}>
                  <Label htmlFor="message">Message</Label>
                  <Textarea
                    rows={6}
                    sx={{
                      borderColor: errors.message ? 'primary' : 'initial',
                    }}
                    {...register('message')}
                  />
                  <ErrorMessage>{errors.message?.message}</ErrorMessage>
                </Box>
                <Button mt={4} type="submit" disabled={isLoading}>
                  {isLoading ? (
                    <>
                      <FontAwesomeIcon icon={faSpinner} pulse /> Submitting...
                    </>
                  ) : (
                    'Get in touch'
                  )}
                </Button>
              </Box>
            </Box>
            <Box>
              <Heading as="h4" color="secondary">
                Address
              </Heading>
              <Paragraph my={0} mt={3} sx={{ fontWeight: 600 }}>
                23 Degrees Sports Management S.L.
              </Paragraph>
              <Paragraph my={0}>PO Box 23</Paragraph>
              <Paragraph my={0}>18140 La Zubia (Granada)</Paragraph>
              <Paragraph my={0}>SPAIN</Paragraph>
              <Heading as="h4" color="secondary" mt={4}>
                Telephone
              </Heading>
              <Paragraph my={0} sx={{ fontWeight: 600 }}>
                <Link href="tel:+34 (958) 892115">+34 (958) 892115</Link>
              </Paragraph>
              <Paragraph>
                <Link
                  href="https://goo.gl/maps/PjBA9GLPaGhq8JKb8"
                  target="_blank"
                >
                  Find us on Google Maps
                </Link>
              </Paragraph>
            </Box>
          </Grid>
        </Container>
      </Box>
    </Layout>
  )
}

const ContactPageRecaptcha: FC = () => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}>
    <ContactPage />
  </GoogleReCaptchaProvider>
)

export default ContactPageRecaptcha
