import React, { FC } from 'react'
import { Grid, Box, Heading, Paragraph, Container } from '@theme-ui/components'
import { Layout } from '../components/layout/layout'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { IAthlete } from '../types/athletes'
import { IArticle } from '../types/articles'
import { InternalLink } from '../components/internal-link/internal-link'
import { SEO } from '../components/seo/seo'

type HomepageProps = {
  allContentfulAthlete: {
    nodes: IAthlete[]
  }
  allContentfulArticle: {
    nodes: IArticle[]
  }
  mainImage: {
    title: string
    gatsbyImageData: IGatsbyImageData
  }
}

const App: FC<PageProps<HomepageProps>> = ({ data }) => {
  const { allContentfulArticle } = data

  const articles = allContentfulArticle.nodes.sort((a, b) => {
    return (
      new Date(b.originallyPublished || b.createdAt).getTime() -
      new Date(a.originallyPublished || a.createdAt).getTime()
    )
  })

  return (
    <Layout>
      <SEO title="News Archive" />
      <Box mt={5} sx={{ textAlign: 'center' }}>
        <Container>
          <Heading
            sx={{
              fontSize: 5,
              fontWeight: 500,
              textTransform: 'uppercase',
            }}
          >
            News Archive
          </Heading>
        </Container>
      </Box>
      <Box my={[4, 5]}>
        <Container>
          <Grid columns={[1, 2, 3]} gap={[4, 4, 3]}>
            {articles.map((article) => (
              <Box mb={4} key={article.slug}>
                <InternalLink url={`/news/${article.slug}/`}>
                  <GatsbyImage
                    image={article.featuredImage?.gatsbyImageData}
                    alt={article.featuredImage?.title}
                  />
                </InternalLink>
                <Paragraph
                  sx={{
                    my: 2,
                  }}
                >
                  {article.originallyPublished || article.createdAt}
                </Paragraph>
                <InternalLink
                  url={`/news/${article.slug}/`}
                  sx={{
                    textDecoration: 'none',
                  }}
                >
                  <Heading
                    sx={{
                      fontWeight: 500,
                      fontSize: 3,
                      textTransform: 'uppercase',
                      color: 'primary',
                    }}
                  >
                    {article.title}
                  </Heading>
                </InternalLink>
              </Box>
            ))}
          </Grid>
        </Container>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query NewsPageQuery {
    allContentfulArticle {
      nodes {
        title
        slug
        createdAt(formatString: "DD MMM YYYY")
        originallyPublished(formatString: "DD MMM YYYY")
        featuredImage {
          title
          gatsbyImageData(
            width: 420
            aspectRatio: 1.75
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
`

export default App
