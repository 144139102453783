import React, { FC } from 'react'
import { Grid, Box, Heading, Paragraph, Container } from '@theme-ui/components'
import { Layout } from '../components/layout/layout'
import { PageProps } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import { SEO } from '../components/seo/seo'

const ClientsPage: FC<PageProps> = () => {
  return (
    <Layout>
      <SEO title="Clients" />
      <Box mt={5} sx={{ textAlign: 'center' }}>
        <Container>
          <Heading
            sx={{
              fontSize: 5,
              fontWeight: 500,
              textTransform: 'uppercase',
            }}
          >
            Clients
          </Heading>
          <Paragraph>
            Over the years, 23 Degrees Sports has worked with numerous leading
            clients in the sports industry, building a reputation for excellence
            and reliability. Whether you are a professional sports team, a
            sports governing body, or a corporate sponsor, 23 Degrees Sports can
            provide the expertise and support you need to achieve your goals.
          </Paragraph>
        </Container>
      </Box>
      <Box
        sx={{
          // bg: `neutral.200`,
          py: [4, 5],
        }}
      >
        <Container>
          <Grid columns={[1, 1, '1fr 3fr']} gap={[2, 2, 4]}>
            <Box
              sx={{
                justifySelf: 'center',
              }}
            >
              <StaticImage
                src="../images/logos/Polygon_Bikes_logo.jpg"
                alt={''}
                width={160}
              />
            </Box>
            <Box>
              <Heading
                sx={{
                  fontWeight: 600,
                  textTransform: 'uppercase',
                }}
              >
                Polygon Bikes
              </Heading>
              <Paragraph>
                Polygon have hired 23 Degrees to provide logistical, management
                and operational services for their Enduro World Cup Team
                starting in 2023. The team will compete in all rounds of the
                Enduro World Cup and be staffed by 23 Degrees contracted
                mechanics, a Social Media/Content Manager, and be managed by
                long time 23 Degrees staff member, Paul Schlitz.
              </Paragraph>
            </Box>
            <Box
              sx={{
                justifySelf: 'center',
              }}
            >
              <StaticImage
                src="../images/logos/image2.png"
                alt={''}
                width={240}
              />
            </Box>
            <Box>
              <Heading
                sx={{
                  fontWeight: 600,
                  textTransform: 'uppercase',
                }}
              >
                Red Bull Media House
              </Heading>
              <Paragraph>
                From 2017 to 2022, 23 Degrees provided all research services to
                the Red Bull TV broadcasts of the UCI MTB World Cup DH and XCO,
                as well as World Championships. These research services were
                provided to both commentary staff and production teams.
              </Paragraph>
            </Box>

            <Box
              sx={{
                justifySelf: 'center',
              }}
            >
              <StaticImage
                src="../images/logos/image4.jpg"
                alt={''}
                width={240}
              />
            </Box>
            <Box>
              <Heading
                sx={{
                  fontWeight: 600,
                  textTransform: 'uppercase',
                }}
              >
                Freecaster
              </Heading>
              <Paragraph>
                From 2009 to 2011, 23 Degrees provided commentary services to
                Freecaster for all XC World Cups events and the occasional DH
                event, as well as research services for the commentary team.
              </Paragraph>
            </Box>

            <Box
              sx={{
                justifySelf: 'center',
              }}
            >
              <StaticImage
                src="../images/logos/monster-logo.png"
                alt={''}
                width={240}
              />
            </Box>
            <Box>
              <Heading
                sx={{
                  fontWeight: 600,
                  textTransform: 'uppercase',
                }}
              >
                Monster Energy / Specialized MTB Team
              </Heading>
              <Paragraph>
                During the year 2008, 23 Degrees was hired by Sean Heimdal
                Racing to write the press releases for the MTB team he managed,
                which included the riders Sam Hill and Brendan Fairclough.
              </Paragraph>
            </Box>

            <Box
              sx={{
                justifySelf: 'center',
              }}
            >
              <StaticImage
                src="../images/logos/image3.jpg"
                alt={''}
                width={240}
              />
            </Box>
            <Box>
              <Heading
                sx={{
                  fontWeight: 600,
                  textTransform: 'uppercase',
                }}
              >
                Honda Racing
              </Heading>
              <Paragraph>
                From 2004 to 2007 the Honda Racing Corporation hired 23 Degrees
                to be program managers for their Team G-Cross Honda Racing Team,
                using the revolutionary Honda RN-01 gearbox bikes. This
                partnership was very successful and resulted in the team winning
                5 World Cup races and an Overall World Cup title, as well as
                many NORBA events.
              </Paragraph>
            </Box>

            <Box
              sx={{
                justifySelf: 'center',
              }}
            >
              <StaticImage
                src="../images/logos/image6.png"
                alt={''}
                width={120}
              />
            </Box>
            <Box>
              <Heading
                sx={{
                  fontWeight: 600,
                  textTransform: 'uppercase',
                }}
              >
                Athens 2004 Summer Olympics
              </Heading>
              <Paragraph>
                In 2003 following the success of the Sydney 2000 Olympic
                Mountain Bike event where company founder Martin Whiteley not
                only identified the race venue but was also the Technical
                Delegate, 23 Degrees signed a multi-year deal to design the 2004
                Olympic MTB course, outside of Athens. The course was widely
                praised by both the competitors and the TV broadcasters.
              </Paragraph>
            </Box>

            <Box
              sx={{
                justifySelf: 'center',
              }}
            >
              <StaticImage
                src="../images/logos/image5.png"
                alt={''}
                width={240}
                aspectRatio={16 / 9}
              />
            </Box>
            <Box>
              <Heading
                sx={{
                  fontWeight: 600,
                  textTransform: 'uppercase',
                }}
              >
                Trixter
              </Heading>
              <Paragraph>
                From 2002 to 2004, together with Greg Minnaar, 23 Degrees
                provided consultancy services to Trixter Fitness-Gaming for the
                development of their Trixter exercise bikes with video consoles.
                These were ahead of their time and were used by Team G-Cross
                Honda in the pits as warm up bikes.
              </Paragraph>
            </Box>

            <Box
              sx={{
                justifySelf: 'center',
              }}
            >
              <StaticImage
                src="../images/logos/image1.jpg"
                alt={''}
                width={240}
              />
            </Box>
            <Box>
              <Heading
                sx={{
                  fontWeight: 600,
                  textTransform: 'uppercase',
                }}
              >
                Carmichael Training Systems
              </Heading>
              <Paragraph>
                In 2002, 23 Degrees provided logistics and planning services for
                CTS, hosting their clients in Spain for a cycling tour following
                the Vuelta a España route of the same year. This meant providing
                route support, hotels and meals, massage therapists, mechanics,
                and an ex-Vuelta a España cyclist as a guide.
              </Paragraph>
            </Box>
          </Grid>
        </Container>
      </Box>
    </Layout>
  )
}

export default ClientsPage
