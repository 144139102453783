import { merge } from 'theme-ui'

import baseTheme from '@theme-ui/preset-base'
import { colors } from './colors'
const theme = merge(baseTheme, {
  buttons: {
    primary: {
      bg: `secondary`,
      fontSize: 1,
      fontWeight: 400,
      cursor: `pointer`,
      '&:hover': {
        bg: `#222`,
      },
      '&:disabled': {
        bg: 'neutral.400',
      },
    },
  },
  colors,
  fonts: {
    ...baseTheme.fonts,
    body: `Open Sans, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif`,
  },
  sizes: {
    container: 1200,
  },
  grids: {
    heading: {
      color: `text`,
    },
  },
  layout: {
    container: {
      px: 3,
    },
  },
  links: {
    button: {
      variant: `styles.a`,
      borderRadius: 5,
      textDecoration: `none`,
      px: 3,
      py: 2,
      bg: `primary`,
      color: `white`,
      fontSize: 2,
      fontWeight: `bold`,
      transition: `all 300ms ease-in-out`,
      '&:hover': {
        bg: `#444`,
      },
    },
    navigation: {
      color: `white`,
      textDecoration: `none`,
      fontSize: 1,
      fontFamily: `heading`,
      fontWeight: 600,
      transition: `all 300ms ease-in-out`,
      '&:hover': {
        color: `accent`,
      },
    },
  },
  styles: {
    a: {},
  },
  text: {
    paragraph: {
      my: 3,
      lineHeight: 'inherit',
    },
    introHeading: {
      fontSize: [4, 4, 5],
      fontWeight: 500,
      color: `white`,
      // textTransform: 'uppercase',
      mb: 4,
    },
    pageHeading: {
      fontSize: 3,
      fontWeight: 700,
      // textTransform: "uppercase",
      mb: 3,
    },
    centreHeading: {
      textAlign: `center`,
      fontSize: 4,
      fontWeight: 400,
      color: `heading`,
      textTransform: `uppercase`,
      mb: 3,
    },
    h2: {
      fontSize: 3,
      fontWeight: 700,
      // textTransform: "uppercase",
      mb: 3,
    },
    h3: {
      fontSize: 2,
      textTransform: 'uppercase',
    },
  },
})

export default theme
