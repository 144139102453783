import { useState } from 'react'
import Axios, { AxiosRequestConfig } from 'axios'

export type useAxiosError = {
  message: string
  code?: string
  status?: string
}

export const useAxios = <T>() => {
  const [response, setResponse] = useState<T>()

  const [error, setError] = useState<undefined | useAxiosError>()
  const [loading, setLoading] = useState(false)

  const axiosRequest =
    (method: 'get' | 'post') =>
    async (url: string, data?: unknown, options?: AxiosRequestConfig) => {
      setLoading(true)
      setError(undefined)
      setResponse(undefined)

      Axios[method]<T>(url, data || options, options)
        .then((responseData) => {
          setResponse(responseData.data)
          return responseData.data
        })
        .catch((err) => {
          if (Axios.isAxiosError(err)) {
            const { code, message } = err
            setError({
              code,
              message,
            })
          }
        })
        .finally(() => setLoading(false))
    }

  const request = {
    get: axiosRequest('get'),
    post: axiosRequest('post'),
  }

  return { request, response, error, loading }
}
