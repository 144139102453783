import { GatsbyBrowser } from 'gatsby'
import { QueryClient, QueryClientProvider } from 'react-query'
import React from 'react'

import 'typeface-open-sans'
import 'typeface-roboto'
import 'typeface-bebas-neue'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
}) => {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>
  )
}
