import { ColorModesScale } from 'theme-ui'

export const colorScales: Record<string, ColorModesScale> = {
  red: {
    500: `#cc0000`,
  },
  neutral: {
    0: `#ffffff`,
    100: `#f9f9f9`,
    200: `#f0f0f0`,
    300: `#eeeeee`,
    400: `#ede9e6`,
    500: `#aeaeb2`,
    600: `#636366`,
    700: `#252626`,
    800: `#222`,
    900: `#141414`,
  },
}

export const colors: ColorModesScale = {
  text: colorScales.neutral[600],
  background: `white`,
  primary: colorScales.red[500],
  secondary: colorScales.neutral[800],
  accent: colorScales.red[500],
  heading: colorScales.neutral[800],
  ...colorScales,
}
