import React, { FC, ReactNode } from 'react'
import {
  Grid,
  Box,
  Heading,
  Paragraph,
  Container,
  Embed,
  Flex,
  Link,
} from '@theme-ui/components'
import { Layout } from '../components/layout/layout'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { IAthlete } from '../types/athletes'
import { ProfileStat } from '../components/profile/profile-stat'
import { ProfileSpacer } from '../components/profile/profile-spacer'
import { RichTextContent } from '../components/rich-text/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import { getVideoUrl } from '../utils/getVideoUrl'
import { SEO } from '../components/seo/seo'
import { InternalLink } from '../components/internal-link/internal-link'
import { IArticle } from '../types/articles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookSquare,
  faInstagram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import 'react-accessible-accordion/dist/fancy-example.css'
import { transformAccordion } from '../utils/transform-accordion'

type AthletePageProps = {
  contentfulAthlete: IAthlete
  allContentfulArticle: {
    nodes: IArticle[]
  }
}

const App: FC<PageProps<AthletePageProps>> = ({ data }) => {
  const { contentfulAthlete, allContentfulArticle } = data

  const transformedHistory = transformAccordion(contentfulAthlete.careerHistory)

  return (
    <Layout>
      <SEO title={contentfulAthlete.name} />
      <Box sx={{ bg: `neutral.700`, color: `white`, py: 5 }}>
        <Container sx={{ maxWidth: 960 }}>
          <Grid
            columns={[1, 1, 2]}
            gap={[2, 5]}
            sx={{
              alignItems: 'center',
            }}
          >
            <Box>
              <Box
                sx={{
                  // border: '5px solid',
                  // borderColor: 'primary',
                  maxWidth: 480,
                }}
              >
                <Box>
                  <GatsbyImage
                    image={contentfulAthlete.profileImage.gatsbyImageData}
                    alt={''}
                    style={{ borderRadius: '50%' }}
                    imgStyle={{ borderRadius: '50%' }}
                  />
                </Box>
              </Box>
            </Box>
            <Box mt={[4, 4, 0]}>
              <Heading
                variant="introHeading"
                sx={{ textTransform: 'uppercase', fontSize: [4, 5], mb: 3 }}
              >
                {contentfulAthlete.name}
              </Heading>
              {contentfulAthlete.nickname && (
                <ProfileStat title="Nickname">
                  {contentfulAthlete.nickname}
                </ProfileStat>
              )}
              <ProfileStat title="Hometown">
                {contentfulAthlete.hometown}
              </ProfileStat>
              <ProfileStat title="Team">
                {contentfulAthlete.teamName}
              </ProfileStat>
              {contentfulAthlete.websiteUrl && (
                <ProfileStat title="Website">
                  <Link href={contentfulAthlete.websiteUrl} target="_blank">
                    {contentfulAthlete.websiteUrl}
                  </Link>
                </ProfileStat>
              )}
              <ProfileSpacer />
              <ProfileStat title="Nationality">
                {contentfulAthlete.nationality}
              </ProfileStat>
              <ProfileStat title="Date of birth">
                {contentfulAthlete.dateOfBirth}
              </ProfileStat>
              <ProfileSpacer />
              <ProfileStat title="Height">
                {contentfulAthlete.height}cm
              </ProfileStat>
              <ProfileStat title="Weight">
                {contentfulAthlete.weight}kg
              </ProfileStat>
              <Flex sx={{ fontSize: 5, mt: 2, gap: 2 }}>
                {contentfulAthlete.instagramUrl && (
                  <Link
                    href={contentfulAthlete.instagramUrl}
                    sx={{ color: 'inherit' }}
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </Link>
                )}
                {contentfulAthlete.facebookUrl && (
                  <Link
                    href={contentfulAthlete.facebookUrl}
                    sx={{ color: 'inherit' }}
                  >
                    <FontAwesomeIcon icon={faFacebookSquare} />
                  </Link>
                )}
              </Flex>
            </Box>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          textAlign: `center`,
          bg: `neutral.200`,
          py: [4, 5],
        }}
      >
        <Container
          sx={{
            maxWidth: 920,
          }}
        >
          <Heading variant="centreHeading">Biography</Heading>
          <Paragraph sx={{ fontSize: [2, 3], lineHeight: 2 }}>
            {contentfulAthlete.biography.biography}
          </Paragraph>
        </Container>
      </Box>
      <Container sx={{ textAlign: 'center' }}>
        {contentfulAthlete.profileVideo && (
          <Embed
            mt={4}
            src={getVideoUrl(contentfulAthlete.profileVideo)}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        )}
        {contentfulAthlete.gallery && (
          <Grid columns={[1, 1, 3]} my={3}>
            {contentfulAthlete.gallery?.map((image) => (
              <GatsbyImage image={image.gatsbyImageData} alt="" />
            ))}
          </Grid>
        )}
      </Container>

      <Container mt={4} sx={{ textAlign: 'center' }}>
        {contentfulAthlete.careerHighlights && (
          <Box my={4}>
            <Heading variant="centreHeading" color="primary">
              Career Highlights
            </Heading>

            {contentfulAthlete.careerHighlights?.map((highlight) => (
              <Paragraph key={highlight} sx={{ fontWeight: 500, fontSize: 3 }}>
                {highlight}
              </Paragraph>
            ))}
          </Box>
        )}
        {transformedHistory && (
          <Box p={4}>
            <Heading variant="centreHeading">Career History</Heading>
            <Container sx={{ maxWidth: 720 }}>
              <Accordion
                allowMultipleExpanded={false}
                preExpanded={[transformedHistory[0].title]}
              >
                {transformedHistory?.map((item) => (
                  <AccordionItem
                    key={item.title}
                    uuid={item.title.replaceAll(' ', '').toLowerCase()}
                  >
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <Box
                          as="span"
                          sx={{
                            fontWeight: 600,
                          }}
                        >
                          {item.title}
                        </Box>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <RichTextContent
                        renderNodeOverrides={{
                          [BLOCKS.PARAGRAPH]: (_, children): ReactNode => {
                            return (
                              <Box m={0} p={0}>
                                {children}
                              </Box>
                            )
                          },
                          [BLOCKS.LIST_ITEM]: (_, children): ReactNode => {
                            return (
                              <Box m={0} p={0}>
                                {children}
                              </Box>
                            )
                          },
                          [BLOCKS.HEADING_3]: (_, children): ReactNode => {
                            return (
                              <Heading as="h3" mt={4} mb={2}>
                                {children}
                              </Heading>
                            )
                          },
                          [BLOCKS.UL_LIST]: (_, children): ReactNode => {
                            return <>{children}</>
                          },
                        }}
                      >
                        {item.content}
                      </RichTextContent>
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </Container>
          </Box>
        )}
        {contentfulAthlete.questionnaire && (
          <Box p={4}>
            <Heading variant="centreHeading">Athlete Profile</Heading>
            <Box>
              <RichTextContent
                renderNodeOverrides={{
                  [BLOCKS.LIST_ITEM]: (_, children): ReactNode => {
                    return (
                      <Paragraph m={0} p={0}>
                        {children}
                      </Paragraph>
                    )
                  },
                  [BLOCKS.UL_LIST]: (_, children): ReactNode => {
                    return <>{children}</>
                  },
                }}
              >
                {contentfulAthlete.questionnaire}
              </RichTextContent>
            </Box>
          </Box>
        )}

        {allContentfulArticle?.nodes && (
          <Box>
            <Heading variant="centreHeading">Related News</Heading>
            <Grid columns={[1, 2, 3]} gap={[4, 4, 3]} mt={4}>
              {allContentfulArticle.nodes?.map((article) => (
                <Box key={article.slug}>
                  <InternalLink url={`/news/${article.slug}/`}>
                    <GatsbyImage
                      image={article.featuredImage?.gatsbyImageData}
                      alt={article.featuredImage?.title}
                    />
                  </InternalLink>
                  <Paragraph
                    sx={{
                      my: 2,
                    }}
                  >
                    {article.originallyPublished || article.createdAt}
                  </Paragraph>
                  <InternalLink
                    url={`/news/${article.slug}/`}
                    sx={{
                      textDecoration: 'none',
                    }}
                  >
                    <Heading
                      sx={{
                        fontWeight: 500,
                        fontSize: 3,
                        textTransform: 'uppercase',
                        color: 'primary',
                      }}
                    >
                      {article.title}
                    </Heading>
                  </InternalLink>
                </Box>
              ))}
            </Grid>
          </Box>
        )}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query AthletePage($slug: String!) {
    contentfulAthlete(slug: { eq: $slug }) {
      biography {
        biography
      }
      name
      nationality
      nickname
      weight
      instagramUrl
      websiteUrl
      hometown
      teamName
      height
      careerHighlights
      careerHistory {
        raw
      }
      gallery {
        gatsbyImageData(
          width: 720
          aspectRatio: 1.5
          placeholder: BLURRED
          layout: FULL_WIDTH
        )
      }
      instagramUrl
      dateOfBirth(formatString: "DD/MM/YYYY")
      profileImage {
        gatsbyImageData(width: 720, aspectRatio: 1, placeholder: BLURRED)
      }
      profileVideo
      questionnaire {
        raw
      }
      slug
    }
    allContentfulArticle(
      sort: { order: DESC, fields: [originallyPublished, createdAt] }
      filter: { relatedAthletes: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      nodes {
        title
        slug
        createdAt(formatString: "DD MMM YYYY")
        originallyPublished(formatString: "DD MMM YYYY")
        featuredImage {
          title
          gatsbyImageData(
            width: 720
            aspectRatio: 1.75
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
`

export default App
